.vertical-timeline-element--date {
  font-size: 30px !important;
  color: rgb(57, 56, 56);
}

.vertical-timeline-element--icon>img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}