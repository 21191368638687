nav{
    top: 0;
    box-shadow: 0px 13px 24px 0px rgba(0,0,0,0.08);
    padding-top: .5rem;
    padding-bottom: .5rem;
  /* background-color: rgba(245, 245, 245, 0.164)!important ;*/
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-weight: 700;
}

nav .navbar-contact {
   color: white !important;
    font-size: 25px;
    font-weight: 500;
}
nav .navbar-collapse.collapse{
    display: block;
    
}
@media (max-width: 991px){
    nav .navbar-collapse.collapse{
        overflow: hidden;
        
    }
    nav .navbar-collapse.active{
        height: 50vh !important;
    } 
  .nav_line {
    display: none;
  }  
}
nav .navbar-collapse{
    transition: .6s all ease-in-out;
    height: 0  !important;
}

.lang_img {
    width: 20px;
}
a:hover {
    color: rgb(227, 155, 46);
}
nav #logo {
    width: 50px;
}

@media (max-width: 500px) {
    nav .navbar-contact {
        font-size: 15px;
    }
    }

    @media (max-width: 380px) {
        nav .navbar-contact {
            font-size: 10px;
        }
        }