.intro {
    position: relative;
    margin: 50px auto 50px auto;
    display: flex;
    justify-content: center;
    align-items: end;
    height: 80vh;
  /* max-width: 800px;*/
    
}

.intro__media {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #ccc url(../images/header.jpg) no-repeat center center;
}

.intro__media::before {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
    content: '';
    background-color: rgba(0, 0, 0, .3);

}

.intro__media-video {
    position: absolute;
    left: 50%;
    top: 50%;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
}

.intro__content {
    z-index: 10;
    text-align: center;
    color: white;
    margin-bottom: 40px;
    opacity: 0.8;
}