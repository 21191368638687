body {
 background: white;
/*background-image: url("../images/paper.jpg");*/
font-family: 'Open Sans', sans-serif;
letter-spacing: 0.05rem;
color: #000;
}

h1, h2, h3 {
  /*  font-family: 'Dancing Script', cursive;*/
    line-height: 1,5em;
    text-shadow:2px 2px 4px rgb(168, 168, 168);
   color: #10A31C;
}


h4, h5, h6 {
  font-family:'Open Sans', sans-serif;
  color: white;
    font-weight: lighter;
}

p {
 font-family: 'Open Sans', sans-serif;

   /* font-style: italic;*/
}
 .btn {
  border-radius: 5px;
  color: aliceblue;
   background-color: #01AC21;
 }
 .btn:hover {
  color: whitesmoke;
  background-color: #02941d;
}

label {
  color: green !important;
}
#forms {
  color: #000 !important;
}

  /* css blog*/

.main-row {
 
    margin-bottom: 8%;
    background-color:rgba(255, 255, 255, 0.705);
    box-shadow: 0 0 10px 10px rgba(0,0,0,.05);
    border-radius: 0.5rem;
    
  }
  
  .blog-img > img {
   width: 250px;
   transform: translateY(-30px);
   border-radius: 50%;
   box-shadow: 0 0 8px 3px rgba(0,0,0,.3);
  }
  .blog-title > h3 {
    line-height: 2rem;
  }
  @media (min-width: 500px) {
  .blog-title > h3 {
    line-height: 3rem;
  }
  }
  @media (min-width: 1100px) {
    .main-row {
      max-width: 800px;
      position: relative;
      left: 15%;
    }
    }

  /* #logo {
    width: 200px;*
}*/